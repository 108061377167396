import urls, { cmsUrls } from 'constants/urls';
import { rootPath } from 'utils/root-path';

import { all } from 'route-data/all';
import { chain } from 'route-data/chain';
import { conditional } from 'route-data/conditional';
import { exceptFromProduct } from 'route-data/except-from-product';
import { lazyFetcher } from 'route-data/lazy-fetcher';
import { isNotEmbeddedInWebView } from 'utils/webview';

const accountsFetcher = lazyFetcher(() => import('redux/fetchers/accounts'), 'accountsFetcher');
const accountsPromptsForContactAddressFetcher = lazyFetcher(
  () => import('redux/fetchers/accounts'),
  'accountsPromptsForContactAddressFetcher',
);
const addressByIdFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'addressByIdFetcher',
);
const addressesFetcher = lazyFetcher(() => import('redux/fetchers/address'), 'addressesFetcher');
const allFavouritesFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites-all'),
  'allFavouritesFetcher',
);
const browseFetcher = lazyFetcher(() => import('redux/fetchers/browse'), 'browseFetcher');
const christmasHubLocationsFetcher = lazyFetcher(
  () => import('redux/fetchers/browse'),
  'christmasHubLocationsFetcher',
);
const bundleFetcher = lazyFetcher(() => import('redux/fetchers/bundle'), 'bundleFetcher');
const campaignsFetcher = lazyFetcher(() => import('redux/fetchers/campaigns'), 'campaignsFetcher');
const checkoutExperienceFragmentFetcher = lazyFetcher(
  () => import('redux/fetchers/checkout-experience-fragment-fetcher'),
  'checkoutExperienceFragmentFetcher',
);
const checkoutRouteLoaded = lazyFetcher(
  () => import('redux/fetchers/checkout-route-loaded'),
  'checkoutRouteLoaded',
);
const citrusAdsProductFetcher = lazyFetcher(
  () => import('redux/fetchers/citrusads-buylist-page'),
  'citrusAdsProductFetcher',
);
const cmsExperienceFragment = (
  ...args: Parameters<typeof import('redux/fetchers/experience-fragment').default>
) =>
  lazyFetcher(
    () => import('redux/fetchers/experience-fragment'),
    'default',
    fetcher => fetcher(...args),
  );
const cmsPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-page'));
const cmsRecipeLandingPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-recipes-landing'));
const mealPlannerFetcher = lazyFetcher(() => import('redux/fetchers/meal-planner'));
const mealPlannerServingSizesFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'fetchMealPlannerServingSizes',
);
const mealPlannerDietaryRequirementsFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'fetchMealPlannerDietaryRequirements',
);

const getProductsForRecipesFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'getProductsForRecipes',
);

const getRecipesForMealPlannerFetcher = lazyFetcher(
  () => import('redux/fetchers/meal-planner'),
  'getRecipesForMealPlanner',
);

const componentDataFetcher = lazyFetcher(
  () => import('redux/fetchers/component-data'),
  'componentDataFetcher',
);
const createAisleFetcher = (
  ...args: Parameters<typeof import('redux/fetchers/custom-aisle').createAisleFetcher>
) =>
  lazyFetcher(
    () => import('redux/fetchers/custom-aisle'),
    'createAisleFetcher',
    fetcher => fetcher(...args),
  );
const customerSlotFetcher = lazyFetcher(
  () => import('redux/fetchers/customer-slot-fetcher'),
  'customerSlotFetcher',
);
const editAdminAddressFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'editAdminAddressFetcher',
);
const favouritesFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites'),
  'favouritesFetcher',
);
const legacyFavouritesFetcher = lazyFetcher(
  () => import('redux/fetchers/legacy-favourites'),
  'legacyFavouritesFetcher',
);
const favouritesSummaryFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites-summary'),
  'favouritesSummaryFetcher',
);
const favouritesCarouselFetcher = lazyFetcher(
  () => import('redux/fetchers/favourites-carousel'),
  'favouritesCarouselFetcher',
);
const fetchCustomerOrderStats = lazyFetcher(
  () => import('redux/modules/orders/actions/fetch-customer-order-stats'),
  'fetchCustomerOrderStats',
);
const footerFetcher = lazyFetcher(() => import('redux/fetchers/footer'), 'footerFetcher');
const homepageFetcher = lazyFetcher(() => import('redux/fetchers/homepage'), 'homepageFetcher');
const incentiveOfferPropositionsFetcher = lazyFetcher(
  () => import('redux/fetchers/incentive-offer-propositions'),
  'incentiveOfferPropositionsFetcher',
);
const incentivesFetcher = (
  ...args: Parameters<typeof import('redux/fetchers/incentives').incentivesFetcher>
) =>
  lazyFetcher(
    () => import('redux/fetchers/incentives'),
    'incentivesFetcher',
    fetcher => fetcher(...args),
  );
const interstitialFetcher = lazyFetcher(
  () => import('redux/fetchers/interstitial'),
  'interstitialFetcher',
);
const interstitialsFetcher = lazyFetcher(
  () => import('redux/fetchers/interstitials'),
  'interstitialsFetcher',
);
const jotterFetcher = lazyFetcher(() => import('redux/fetchers/jotter'), 'jotterFetcher');
const mealDealFetcher = lazyFetcher(() => import('redux/fetchers/meal-deal'), 'mealDealFetcher');
const membershipStatusFetcher = lazyFetcher(
  () => import('redux/fetchers/membership-status'),
  'membershipStatusFetcher',
);
const menuFetcher = lazyFetcher(() => import('redux/fetchers/menu'), 'menuFetcher');
const multiSearchFetcher = lazyFetcher(
  () => import('redux/fetchers/multi-search'),
  'multiSearchFetcher',
);
const multiSearchFetcherLoading = lazyFetcher(
  () => import('redux/fetchers/multi-search-loading'),
  'multiSearchFetcherLoading',
);
const myWaitroseFetcher = lazyFetcher(
  () => import('redux/fetchers/my-waitrose'),
  'myWaitroseFetcher',
);
const navigationFetcher = lazyFetcher(
  () => import('redux/fetchers/navigation'),
  'navigationFetcher',
);
const newJoinerAddressesFetcher = lazyFetcher(
  () => import('redux/fetchers/address'),
  'newJoinerAddressesFetcher',
);
const offersFetcher = lazyFetcher(() => import('redux/fetchers/offers'), 'offersFetcher');
const orderByURLFetcher = lazyFetcher(() => import('redux/fetchers/order'), 'orderByURLFetcher');
const orderInitialiser = lazyFetcher(() => import('redux/fetchers/order'), 'orderInitialiser');
const orderProductsByUrlFetcher = lazyFetcher(
  () => import('redux/fetchers/order'),
  'orderProductsByUrlFetcher',
);
const ordersFetcher = lazyFetcher(() => import('redux/fetchers/orders'), 'ordersFetcher');
const paymentCardsFetcher = lazyFetcher(
  () => import('redux/fetchers/payment-cards'),
  'paymentCardsFetcher',
);

const paymentConfirmationFetcher = lazyFetcher(
  () => import('redux/fetchers/payment-confirmation'),
  'paymentConfirmationFetcher',
);

const productFetcher = lazyFetcher(() => import('redux/fetchers/product'), 'productFetcher');
const recentOrdersFetcher = lazyFetcher(
  () => import('redux/fetchers/recent-orders'),
  'recentOrdersFetcher',
);
const recipeFetcher = lazyFetcher(() => import('redux/fetchers/recipes'), 'recipeFetcher');
const recipeProductFetcher = lazyFetcher(
  () => import('redux/fetchers/recipes'),
  'recipeProductFetcher',
);
const resolveOrderPaymentFetcher = lazyFetcher(
  () => import('redux/fetchers/resolve-order-payment'),
  'resolveOrderPaymentFetcher',
);
const resolveOrderPaymentIfOrderPaymentFailed = lazyFetcher(
  () => import('redux/fetchers/resolve-order-payment-if-order-payment-failed'),
  'resolveOrderPaymentIfOrderPaymentFailed',
);
const reviewsTokenFetcher = lazyFetcher(
  () => import('redux/fetchers/reviews-token'),
  'reviewsTokenFetcher',
);
const searchFetcher = lazyFetcher(() => import('redux/fetchers/search'), 'searchFetcher');
const shoppingListFetcher = lazyFetcher(
  () => import('redux/fetchers/shopping-list'),
  'shoppingListFetcher',
);
const shoppingListsFetcher = lazyFetcher(
  () => import('redux/fetchers/shopping-lists'),
  'shoppingListsFetcher',
);
const shoppingListsProductsFetcher = lazyFetcher(
  () => import('redux/fetchers/shopping-lists-products'),
  'shoppingListsProductsFetcher',
);
const singleOfferFetcher = lazyFetcher(
  () => import('redux/fetchers/single-offer'),
  'singleOfferFetcher',
);
const slotExperience = (
  ...args: Parameters<typeof import('redux/fetchers/slot-experience').default>
) =>
  lazyFetcher(
    () => import('redux/fetchers/slot-experience'),
    'default',
    fetcher => fetcher(...args),
  );
const trolleyFetcher = lazyFetcher(() => import('redux/fetchers/trolley'), 'trolleyFetcher');
const trolleyWithRecommendationsFetcher = lazyFetcher(
  () => import('redux/fetchers/trolley'),
  'trolleyWithRecommendationsFetcher',
);

const loggedInCustomerDataFetcher = lazyFetcher(
  () => import('redux/fetchers/logged-in-customer-data'),
);

const cmsPaths = cmsUrls.map(path => ({
  exact: !path.includes('/:path*'),
  fetch: all(chain(cmsPageFetcher, componentDataFetcher), navigationFetcher),
  path,
}));

// This is a switch-like table; only the first matching route will be used.
// Each route object can have props 'path', 'exact' and 'strict', as per <Route/>.
//
// If a path matches, the 'fetch' function will be invoked, passing { location, match },
// and the action returned will be dispatched. If a 'clientFetch' fetcher is set, it too will
// be invoked, but only on the client-side.
//
// On the client-side, used by <DataProvider/> to fetch data based on location
// On the server-side, used by fetchOnServer() to fetch data, based on express request object
const paths = [
  ...cmsPaths,
  {
    path: rootPath('/shop/browse/offers/highlights/:offerType'),
    fetch: exceptFromProduct(all(offersFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse/offers'),
    fetch: exceptFromProduct(all(offersFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse/groceries/christmas'),
    fetch: exceptFromProduct(
      all(
        chain(menuFetcher, browseFetcher, christmasHubLocationsFetcher, allFavouritesFetcher),
        footerFetcher,
      ),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/browse'),
    fetch: exceptFromProduct(
      all(chain(menuFetcher, browseFetcher, allFavouritesFetcher), footerFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  { path: rootPath('/shop/featured/entertaining'), exact: true }, // Redirects on render
  { path: rootPath('/shop/featured/groceries'), exact: true }, // Redirects on render
  { path: rootPath('/shop/featured/offers'), exact: true }, // Redirects on render
  {
    path: rootPath('/shop/featured/entertaining/**'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('entertaining'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/shop/featured/groceries/**'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('groceries'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: `${urls.mealDealPromoPath}/:mealDealId(\\d+)`,
    fetch: exceptFromProduct(all(mealDealFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath('/shop/featured/offers/**'),
    fetch: exceptFromProduct(
      all(createAisleFetcher('offers'), allFavouritesFetcher, navigationFetcher),
    ),
    clientFetch: componentDataFetcher,
  },
  {
    path: `${urls.bundles}/:bundleName`,
    fetch: exceptFromProduct(all(bundleFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath('/shop/multi-search'),
    fetch: all(allFavouritesFetcher, multiSearchFetcherLoading, navigationFetcher),
    clientFetch: multiSearchFetcher,
  },
  {
    path: rootPath('/shop/offers/:offerId(-?\\d+)'),
    fetch: exceptFromProduct(all(singleOfferFetcher, allFavouritesFetcher, navigationFetcher)),
  },
  {
    path: rootPath(
      "/shopping-lists/:shoppingListName([a-zA-Z0-9'@£%&+!?., -]*)?/:shoppingListId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[34][0-9a-fA-F]{3}-[89ab][0-9a-fA-F]{3}-[0-9a-fA-F]{12})",
    ),
    fetch: all(shoppingListFetcher, allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/shop/search'),
    fetch: exceptFromProduct(all(searchFetcher, allFavouritesFetcher, navigationFetcher)),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/products/:productName/:productId(\\d{6}-\\d+-\\d+)'),
    fetch: all(
      productFetcher,
      shoppingListsFetcher,
      reviewsTokenFetcher,
      allFavouritesFetcher,
      navigationFetcher,
    ),
  },
  {
    path: rootPath('/favourites'),
    fetch: exceptFromProduct(
      all(favouritesFetcher, favouritesCarouselFetcher, allFavouritesFetcher, navigationFetcher),
    ),
  },
  {
    path: rootPath('/lists'),
    fetch: exceptFromProduct(
      all(chain(shoppingListsFetcher, shoppingListsProductsFetcher), navigationFetcher),
    ),
  },
  {
    path: rootPath('/myaccount/marketingpreferences'),
    fetch: all(membershipStatusFetcher, navigationFetcher),
  },
  {
    path: rootPath('/myaccount'),
    fetch: all(
      accountsPromptsForContactAddressFetcher,
      addressesFetcher,
      membershipStatusFetcher,
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/myaccount/paymentcards'),
    fetch: all(paymentCardsFetcher, navigationFetcher),
  },
  {
    path: rootPath('/my-details'),
    fetch: all(
      accountsFetcher,
      accountsPromptsForContactAddressFetcher,
      addressesFetcher,
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/link-card'),
    fetch: all(newJoinerAddressesFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/join'),
    fetch: all(newJoinerAddressesFetcher, membershipStatusFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/customer/address'),
    fetch: all(editAdminAddressFetcher),
  },
  {
    path: rootPath('/beforeYouGo/:interstitialId'),
    fetch: all(interstitialFetcher, navigationFetcher),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/beforeYouGo'),
    fetch: all(interstitialsFetcher, allFavouritesFetcher, navigationFetcher),
    clientFetch: componentDataFetcher,
  },
  {
    path: rootPath('/my-waitrose'),
    fetch: exceptFromProduct(
      all(
        addressesFetcher,
        myWaitroseFetcher,
        allFavouritesFetcher,
        legacyFavouritesFetcher,
        favouritesSummaryFetcher,
        cmsExperienceFragment('mywaitrose/my-waitrose-benefits/master', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/my-waitrose-benefits/benefits-summary', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/competitions/master', {
          applyColumnTransformation: true,
        }),
        cmsExperienceFragment('mywaitrose/vouchers/vouchers-alert/master'),
        navigationFetcher,
      ),
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/become-a-member'),
    fetch: all(
      cmsExperienceFragment('mywaitrose/become-a-member/master', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/card-and-details'),
    fetch: all(
      accountsPromptsForContactAddressFetcher,
      myWaitroseFetcher,
      addressesFetcher,
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/competitions'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/Competitions/competitions/master', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/central-area-frame', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/dry-cleaning'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/dry-cleaning', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/free-tea-or-coffee'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/free-hot-drink', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/caffe-nero'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/caffe-nero', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/vitality'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/vitality', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/benefits/waitrose-magazines'),
    fetch: all(
      myWaitroseFetcher,
      cmsExperienceFragment('mywaitrose/my-waitrose-benefits/waitrose-magazines', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/vouchers'),
    fetch: all(
      myWaitroseFetcher,
      incentivesFetcher(),
      incentiveOfferPropositionsFetcher,
      cmsExperienceFragment('mywaitrose/vouchers/vouchers-alert/master'),
      cmsExperienceFragment('mywaitrose/vouchers/competitions-banner/master', {
        applyColumnTransformation: true,
      }),
      cmsExperienceFragment('mywaitrose/my-waitrose-rewards/faqs/master', {
        applyColumnTransformation: true,
      }),
      navigationFetcher,
    ),
    exact: true,
  },
  {
    path: rootPath('/leave-my-waitrose'),
    fetch: all(addressesFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/order-replacement-card/address'),
    fetch: all(addressesFetcher, myWaitroseFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/join/welcome'),
    fetch: all(addressesFetcher, membershipStatusFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-waitrose/link-card/welcome'),
    fetch: all(addressesFetcher, membershipStatusFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/my-partnership/card-and-details'),
    fetch: all(accountsFetcher, myWaitroseFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: rootPath('/*/addresses/edit/:addressId'),
    fetch: all(addressByIdFetcher),
  },
  {
    path: rootPath('/checkout'),
    fetch: chain(
      all(
        trolleyFetcher,
        accountsFetcher,
        fetchCustomerOrderStats,
        addressesFetcher,
        orderInitialiser,
        paymentCardsFetcher,
        incentivesFetcher({
          ignoreErrors: true,
          timeout: { handleTimeout: true, deadline: 8000, response: 8000 },
        }),
      ),
      checkoutRouteLoaded,
      checkoutExperienceFragmentFetcher,
      customerSlotFetcher,
      allFavouritesFetcher,
    ),
    exact: true,
    noCommon: true,
  },
  {
    path: rootPath('/resolve-order-payment/:customerOrderId'),
    noCommon: true,
    fetch: chain(
      all(
        resolveOrderPaymentFetcher,
        addressesFetcher,
        cmsExperienceFragment('buy/checkout/payment-alert/master', {
          numberOfRetries: 1,
          cache: true,
        }),
      ),
      checkoutRouteLoaded,
    ),
  },
  {
    path: rootPath('/payment-confirmation/:customerOrderId'),
    fetch: all(paymentConfirmationFetcher, navigationFetcher),
  },
  {
    path: rootPath('/order-confirmation/:customerOrderId'),
    noCommon: true,
    fetch: chain(
      loggedInCustomerDataFetcher,
      all(
        orderByURLFetcher,
        conditional(
          isNotEmbeddedInWebView,
          all(
            // Addresses are not necessary in apps as we don't have any way to get to them directly in the webview
            addressesFetcher,
            // Used for multi-search. Not necessary in apps as they don't have the header
            jotterFetcher,
            cmsExperienceFragment('buy/checkout/charity/master', {
              applyColumnTransformation: true,
            }),
            cmsExperienceFragment('buy/checkout/order-confirmation-ads/master', {
              applyColumnTransformation: true,
            }),
            navigationFetcher,
          ),
        ),
      ),
    ),
  },
  {
    path: rootPath('/shop/trolley'),
    fetch: all(
      trolleyWithRecommendationsFetcher,
      cmsExperienceFragment('buy/trolley/trolley-advert/master'),
      navigationFetcher,
    ),
  },
  {
    path: rootPath('/recipe/:recipeId'),
    fetch: all(recipeFetcher, recipeProductFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/builder'),
    exact: false,
    fetch: all(
      navigationFetcher,
      chain(
        mealPlannerFetcher,
        getRecipesForMealPlannerFetcher,
        getProductsForRecipesFetcher,
        componentDataFetcher,
      ),
    ),
  },
  {
    path: rootPath('/recipes/meal-plans/serves'),
    exact: false,
    fetch: all(mealPlannerServingSizesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/dietary'),
    exact: false,
    fetch: all(mealPlannerDietaryRequirementsFetcher, navigationFetcher),
  },
  {
    path: rootPath('/recipes/meal-plans/dietary'),
    exact: false,
    fetch: all(navigationFetcher, mealPlannerDietaryRequirementsFetcher),
  },
  {
    path: rootPath('/recipes/:recipecollection*'),
    exact: false,
    fetch: all(chain(cmsRecipeLandingPageFetcher, componentDataFetcher), navigationFetcher),
  },
  {
    path: rootPath('/recipes'),
    exact: true,
    fetch: all(chain(cmsPageFetcher, componentDataFetcher), navigationFetcher),
  },
  {
    path: `${urls.citrusAdsBuylistPage}/:lineNumbers`,
    fetch: all(citrusAdsProductFetcher, allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/registration'),
    noCommon: true,
    fetch: footerFetcher,
  },
  {
    path: rootPath('/bookslot/collection/entertaining'),
    fetch: all(allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/bookslot/collection/groceries**'), // groceries === groceriesandentertaing
    fetch: all(allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/bookslot/delivery'),
    fetch: all(allFavouritesFetcher, navigationFetcher),
  },
  {
    path: rootPath('/bookslot/collection/selectcollectionservice'),
    fetch: all(
      slotExperience({
        experienceFragmentKeys: [],
        slotsPage: 'SELECT_COLLECTION_SERVICE',
        slotType: null,
      }),
      campaignsFetcher,
      navigationFetcher,
    ),
  },
  {
    path: urls.serviceSelection,
    noCommon: true,
    fetch: lazyFetcher(() => import('components/BookSlot/ServiceSelection'), 'fetch'),
    clientFetch: lazyFetcher(() => import('components/BookSlot/ServiceSelection'), 'clientFetch'),
  },
  {
    path: `${urls.forgotPassword}/**`,
    noCommon: true,
    fetch: footerFetcher,
  },
  {
    path: rootPath('/log-out'),
    noCommon: true,
    fetch: footerFetcher,
  },
  {
    path: urls.myOrdersPage,
    fetch: all(ordersFetcher, navigationFetcher),
    exact: true,
  },
  {
    path: urls.shopFromPrevious,
    fetch: all(
      recentOrdersFetcher,
      allFavouritesFetcher,
      chain(orderByURLFetcher, orderProductsByUrlFetcher),
      navigationFetcher,
    ),
  },
  {
    path: urls.shopFromPreviousInterstitial,
    fetch: all(recentOrdersFetcher, navigationFetcher),
  },
  {
    path: urls.viewOrder,
    fetch: all(
      chain(
        orderByURLFetcher,
        all(orderProductsByUrlFetcher, resolveOrderPaymentIfOrderPaymentFailed),
      ),
      navigationFetcher,
    ),
  },
  { path: '/', exact: true, fetch: all(homepageFetcher, navigationFetcher) },
  { path: '/', fetch: navigationFetcher }, // Catch-all
];

// Fetchers here are invoked for every route
const common = {
  createFetcherChain: (fetchers: unknown) =>
    lazyFetcher(
      () => import('route-data/common-fetch'),
      'createFetcherChain',
      fetcher => fetcher(fetchers),
    ),
  clientFetch: lazyFetcher(() => import('route-data/common-client-fetch'), 'commonClientFetch'),
};

export const routes = paths.map(path => ({
  ...path,
  ...((path.fetch || path.clientFetch) && {
    fetch: path.noCommon ? path.fetch : common.createFetcherChain(path.fetch),
    clientFetch: path.noCommon ? path.clientFetch : all(common.clientFetch, path.clientFetch),
  }),
}));
